<template>
  <div>
    <van-cell
      v-for="item in list"
      :key="item + 'InNews'"
      style="margin-top:3%; float:left;"
      @click="onclickbtn(item.id)"
    >
      <template slot="icon">
        <van-image width="6em" height="6em" fit="contain" :src="item.logo" alt="动态新闻" />
      </template>
      <template slot="title">
        <van-notice-bar color="#57c4b7" background="white" :text="item.title"/>
      </template>
      <template slot="label">
        <span style="margin-left:15px;">{{item.summay}}</span>
      </template>
    </van-cell>
  </div>
</template>
<script>
import ajax from "../../lib/ajax";
import { Toast } from "vant";
import store from "../../store";
import AppConsts from "../../lib/appconst";
export default {
  data() {
    return {
      list: [],
      logos: require('@/assets/FGlogo.png'),
      // logos: require('@/assets/JClogo.png'),
      // logos: require("@/assets/LClogo.jpg"),
      // imgsrc: require("@/assets/LCInfo.jpeg"),
    };
  },
  created() {
    this.onload();
  },
  methods: {
    async onload() {
      await ajax
        .get("/Api/WechatNews/QueryNewsList?input=7")
        .then(res => {
          this.list = res.data.result;
        //   console.log(this.list);
        //   // let token = sessionStorage.getItem("authToken");
          for (let i = 0; i < this.list.length; i++) {
        //     let name = this.list[i].docName.split(".");
        //     console.log(name);
        //     this.list[i].dep = name[0];
        //     this.list[i].doc = name[1];
        //     this.list[i].lev = name[2];
            let http = this.list[i].imageKey;
            this.logos =
              http === null || http === ""
                ? this.logos
                : "https://api.fgxrmyy.cn/api/Attachment/" + http;
            this.list[i].logo = this.logos;
            // let key = this.list[i].key;
            // var id = "DocImg" + this.list[i].id;
            // console.log(id);
            // this.getImg(key,id);
          }
          // console.log(this.list);
        })
        .catch(err => {
          Toast("错误：" + err.message);
        });
    },
    onclickbtn(id) {
      // alert(doc);
      store.commit("store/setDorname", id);
      this.$router
        .replace({
          path: "/NewsI"
        })
        .catch(err => {
          console.log(err);
        });
    },
    getImg(key, id) {
      let Url = AppConsts.remoteServiceBaseUrl;
      let token = sessionStorage.getItem("authToken");
      var url = Url + "api/Attachment/" + key;
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "blob"; //也可以使用POST方式，根据接口
      xhr.setRequestHeader("Authorization", " Bearer " + token);
      xhr.onload = function(res) {
        if (this.status === 200) {
          console.log(res);
          var type = xhr.getResponseHeader("Content-Type");
          var blob = new Blob([this.response], { type: type });
          if (typeof window.navigator.msSaveBlob !== "undefined") {
            window.navigator.msSaveBlob(blob);
          } else {
            var URL = window.URL || window.webkitURL;
            var objectUrl = URL.createObjectURL(blob);
            console.log(id);

            var img = document.getElementById(id);
            img.setAttribute("src", objectUrl);
            // this.logos = objectUrl;
          }
        } else {
          Toast("获取医生图片失败!");
        }
      };
      xhr.send();
    }
  }
};
</script>
<style>
</style>